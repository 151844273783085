import React from 'react';
import { Flex, useTokens } from '@mezzoforte/forge';
import { Company } from '../../../types/Company';

const { colors, fontSizes } = useTokens.huutokaupat;

export interface CompanyIconProps {
  readonly company: Company;
}

export const CompanyIcon = ({ company }: CompanyIconProps) => {
  const initials = company.name
    .toUpperCase()
    .split(' ')
    .map((word) => word[0])
    .join('')
    .substring(0, 2);

  return (
    <Flex
      background={company.isConsumer ? colors.brand : colors.success}
      color={colors.textInvert}
      minWidth="24px"
      minHeight="24px"
      borderRadius="50%"
      fontSize={fontSizes.supplementary}
      justifyContent="center"
      alignItems="center"
    >
      {initials}
    </Flex>
  );
};
