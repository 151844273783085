import { Heart, HourglassHigh, List, SquaresFour, Star, UserCircle } from '@mezzoforte/forge-icons';
import { Content } from '@prismicio/client';
import { asLink } from '@prismicio/helpers';
import { UseQueryResult } from '@tanstack/react-query';
import { NavSubItem } from 'features/SiteHeader/MainNavigationItem';
import { Session } from 'types/Session';
import { User } from 'types/User';
import { splitBy } from 'util/array';
import { resolve } from 'util/url';
import { linkResolver } from '../../prismicio';

export type Flatten<T> = T extends any[] ? T[number] : T;
export type SubMenuItem = Content.MainNavigationItemSliceDropdownItem | Content.MainNavigationItemSliceUserMenuItem;

export const icons = {
  SquaresFour,
  UserCircle,
  Star,
  Heart,
  HourglassHigh,
  List,
} as const;

function userRoles(user?: User | null): Flatten<Content.MainNavigationItemSliceDropdownItem['display_for']>[] {
  if (!user) return ['Everyone', 'Unauthenticated'];
  const roleStrings = ['Everyone', 'Authenticated', 'Buyer', 'Seller', 'Accounting', 'Admin'] as const;
  const conditions = [true, true, user.isBuyer, user.isSeller, user.isAccounting, user.isAdmin];
  return roleStrings.filter((_, i) => conditions[i]);
}

function filterForVisitor(item: SubMenuItem, user?: User | null) {
  const roles = userRoles(user);
  if (roles.includes(item.hide_for)) return false; // explicit disallow list
  if (!roles.includes(item.display_for)) return false; // explicit allow list
  return true;
}

export function menuItemsFromSlice(slice: Content.MainNavigationItemSlice, currentUser: UseQueryResult<Session>) {
  return splitBy<SubMenuItem>((item) => item.type === 'Spacer', slice.items).map(
    (list) =>
      list
        .filter((item) => filterForVisitor(item, currentUser.data?.user))
        .map<NavSubItem | undefined>((item) => {
          switch (item.type) {
            case 'UserGreeting':
              return {
                type: 'UserGreeting',
                label: 'Hei!',
              };
            case 'Link':
              return {
                type: 'Link',
                label: item.title ?? '',
                href: resolve(asLink(item.link, linkResolver) ?? '#'),
              };
            case 'Text':
              return { type: 'Text', label: item.title ?? '' };
            default:
              return undefined;
          }
        })
        .filter((item) => item !== undefined) as NavSubItem[]
  );
}
