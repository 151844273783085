import { useContext } from 'react';
import styled from '@emotion/styled';
import { Box, useTokens, type BoxProps } from '@mezzoforte/forge';
import { ShadowBox } from 'components/Box/ShadowBox';
import { type NavSubItem, NavItemContext } from './MainNavigationItem';
import type { NavItemDropdown, NavItemDropdownFullwidth } from './MainNavigationItem';
import { UserGreeting } from '../../components/Header/UserGreeting/UserGreeting';

export interface SubMenuProps {
  items: NavSubItem[][];
}

const { colors } = useTokens.huutokaupat;

const MenuWrapper: React.FC<BoxProps> = (props) => (
  <ShadowBox
    position="absolute"
    top={{ base: 'initial', lg: 76 }}
    bottom={{ base: 'calc(80px + env(safe-area-inset-bottom))', lg: 'initial' }}
    left={{ base: 3, lg: '-24px' }}
    right={{ base: 3, lg: 'initial' }}
    as="ul"
    m={0}
    p={0}
    minWidth={266}
    overflow="auto"
    {...props}
  />
);

export const SubMenu: React.FC<{ display: boolean; item: NavItemDropdown | NavItemDropdownFullwidth }> = ({
  display,
  item,
}) => {
  const positioningProps: BoxProps =
    item.type === 'DropdownFullwidth'
      ? {
          position: { base: 'fixed', lg: 'absolute' },
          top: { base: 3, lg: 93 },
          left: { base: 3, lg: 0 },
          right: { base: 3, lg: 0 },
        }
      : {};

  return (
    <>
      {item.customMenu && (
        <MainNavigationCustomSubMenu display={display ? 'block' : 'none'} {...positioningProps}>
          {item.customMenu}
        </MainNavigationCustomSubMenu>
      )}
      {item.items && (
        <MainNavigationSubMenu
          display={display ? 'block' : 'none'}
          items={item.items}
          maxHeight="calc(100dvh - 90px)"
        />
      )}
    </>
  );
};

const MainNavigationCustomSubMenu: React.FC<BoxProps> = (props) => <MenuWrapper {...props} />;

const MainNavigationSubMenu: React.FC<SubMenuProps & BoxProps> = ({ items, ...props }) => {
  const { close } = useContext(NavItemContext);
  return (
    <MenuWrapper {...props}>
      {items.map((group) => (
        <ItemGroup key={JSON.stringify(group)} borderColor="lineBorder" py={2}>
          {group.map((item) => {
            const text = (
              <Box as="span" display="block" px={4} py={2}>
                {item.label}
              </Box>
            );

            if (item.type === 'UserGreeting') {
              return <UserGreeting key={item.label} />;
            }

            return (
              <SubMenuItem as="li" key={item.label} fontSize={14} fontWeight={500} data-test="main-nav-submenu-item">
                {item.type === 'Link' && (
                  <a href={item.href} onClick={close}>
                    {text}
                  </a>
                )}
                {item.type === 'Text' && (
                  <Box as="span" color="subdued">
                    {text}
                  </Box>
                )}
              </SubMenuItem>
            );
          })}
        </ItemGroup>
      ))}
    </MenuWrapper>
  );
};

const ItemGroup = styled(Box)`
  & + & {
    border-top-width: 1px;
    border-top-style: solid;
  }
`;

const SubMenuItem = styled(Box)`
  color: black;
  display: block;

  :has(a:hover) {
    background-color: ${colors.backgroundGray};
  }

  a {
    color: inherit;
    text-decoration: none;
    white-space: nowrap;
  }
`;
