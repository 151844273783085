import { useEffect, useMemo } from 'react';
import { useSession } from 'hooks/useSession';
import { useQuery } from '@tanstack/react-query';

export function getSelectedCompanyId() {
  const value = window.localStorage.getItem('selectedCompanyId');
  if (value === null || value === undefined) {
    return null;
  }

  return parseInt(value, 10);
}

function saveSelectedCompanyId(companyId: number) {
  window.localStorage.setItem('selectedCompanyId', companyId.toString());
}

export function clearSelectedCompanyId() {
  window.localStorage.removeItem('selectedCompanyId');
}

export const useCompanySelection = () => {
  const { currentUser } = useSession();
  const selectedCompanyIdQuery = useQuery({ queryKey: ['selected-company-id'], queryFn: getSelectedCompanyId });

  const selectedCompany = useMemo(() => {
    if (!selectedCompanyIdQuery.data || !currentUser.data?.isAuthenticated) {
      return undefined;
    }

    return currentUser.data.companies.find((company) => company.id === selectedCompanyIdQuery.data);
  }, [selectedCompanyIdQuery.data, currentUser.data]);

  useEffect(() => {
    if (currentUser.isFetching) {
      return;
    }

    if (!currentUser.data?.isAuthenticated) {
      // Don't clear selected company to keep it in case the same user will be next to log in
      // The company selection will stay even if a different user logs in and has access to the same company, I don't think that's great but really how common can it be?
      return;
    }

    const userCompanies = currentUser.data.companies;
    if (!userCompanies || userCompanies.length === 0) {
      clearSelectedCompanyId();
      return;
    }

    const isInvalidCompanySelection = !userCompanies.find((company) => company.id === selectedCompanyIdQuery.data);
    if (isInvalidCompanySelection || selectedCompanyIdQuery.data === null) {
      const consumerCompany = userCompanies.find((company) => company.isConsumer);
      saveSelectedCompanyId(consumerCompany?.id ?? userCompanies[0].id);
      return;
    }
  }, [currentUser.isFetching, currentUser.data, selectedCompanyIdQuery.data]);

  function selectCompanyId(companyId: number) {
    saveSelectedCompanyId(companyId);
    void selectedCompanyIdQuery.refetch();
  }

  return {
    selectCompanyId,
    selectedCompany,
  };
};
